/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import TopNav from "./TopNav"
import Link from "./Link"
import { makeStyles } from "@material-ui/styles"
import Meta from "./Meta"
import "./layout.scss"
import Footer from "./Footer"
import GlobalAlert from "./GlobalAlert"
import { store } from "../app/store"
import { Provider } from "react-redux"
import CookieModal from "./CookieModal"

const useStyles = makeStyles(theme => ({
  pageCTA: {
    position: "fixed",
    bottom: 30,
    right: 22,
    left: 22,
    width: "calc(100% - 44px)",
    display: "block",
    borderRadius: 4,
    fontFamily: theme.headerFont,
    padding: "12px 22px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 400,
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "none",
    zIndex: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    transition: "bottom .3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      width: 310,
      bottom: 50,
      right: 50,
      left: "auto",
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  pageCTATheme: {
    ...theme.pageCTATheme,
  },
}))

const Layout = props => {
  const classes = useStyles()

  let button = null
  let buttonLink = null
  let buttonText = null

  if (props != null && !props?.pageContext?.wedding) {
    if (props.cta != null) {
      if (props.cta.actionType != null) {
        buttonText = props.cta.ctaText
        switch (props.cta.actionType) {
          case "Make a Booking":
          default:
            if (props.themeOptions.accommodationActive !== "true") {
              buttonLink = "/book-a-table/"
            } else if (props.themeOptions.tableBookingActive !== "true") {
              buttonLink = props.themeOptions.roomBookingLink
            } else {
              buttonLink = "/booking/"
            }
            break
          case "Book a table":
            if (props.themeOptions.accommodationActive !== "true") {
              buttonLink = "/book-a-table/"
            } else {
              buttonLink = "/booking/book-a-table/"
            }
            break
          case "Book a room":
            buttonLink = props.themeOptions.roomBookingLink
            break
          case "Link":
            buttonLink = props.cta.link && props.cta.link.url
            break
        }

        // If nothiung is active then the button is null
        if (
          props.themeOptions.accommodationActive !== "true" &&
          props.themeOptions.tableBookingActive !== "true" &&
          props.cta.link === null
        ) {
          button = null
        } else {
          button = (
            <Link
              className={` ${classes.pageCTA} ${classes.pageCTATheme}`}
              to={buttonLink}
            >
              {buttonText}
            </Link>
          )
        }
      }
    }
  }

  return (
    <>
      <Provider store={store}>
        <CssBaseline />

        <style>
          {
            "html,body{max-width:100%;overflow-x:hidden;}body{position:relative}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"
          }
        </style>
        <Meta
          meta={props.meta}
          title={props.title}
          path={props.path}
          ii={props.ii}
          faqs={props.faqs ? props.faqs : null}
        />
        <TopNav
          buttonLink={buttonLink}
          buttonText={buttonText}
          pageContext={props.pageContext}
        />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1130,
            padding: `0 1.0875rem`,
          }}
        >
          <main>{props.children}</main>
          <CookieModal button={button} />
          <Footer />
        </div>
        <GlobalAlert />
      </Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
}

export default Layout
